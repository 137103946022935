import axios from '@/http/axios'


function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getSaleAddons (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/sale-addons${queries}`,
    method: 'get'
  })
}

export function getSaleAddon (id) {
  return axios({
    url: `v1/admin/sale-addons/${id}`,
    method: 'get'
  })
}

export function insertSaleAddon (payment) {
  return axios({
    url: 'v1/admin/sale-addons',
    data: payment,
    method: 'post'
  })
}

export function editSaleAddon (id, payment) {
  return axios.patch(`v1/admin/sale-addons/${id}`, payment)
}


export function deleteSaleAddon (id, force = false) {
  return axios({
    url: `v1/admin/sale-addons/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreSaleAddon (id) {
  return axios({
    url: `v1/admin/sale-addons/${id}/restore`,
    method: 'put'
  })
}


export function getSaleAddonsTrash (page, filters = [], sorts = []) {

  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/sale-addons?trashed=true${queries}`,
    method: 'get'
  })
}
