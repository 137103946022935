<template>
  <div class="sale-addon-trash md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="saleAddonsTrash"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getSaleAddonsTrash"
                             @saleAddon:restore="restoreSaleAddon($event)"
                             @saleAddon:deleted="$refs.deleteConfirmation.showDialog(), saleAddonDataForDelete = $event"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="$t('sales.addons.confirmations.forceDelete.title')"
                   :body="$t('sales.addons.confirmations.forceDelete.body', {name: saleAddonDataForDelete.name})"
                   @accept="deleteSaleAddon(saleAddonDataForDelete)"
                   @close=""/>
  </div>
</template>

<script>
  import { getSaleAddonsTrash, restoreSaleAddon, deleteSaleAddon } from "../../../../../http/requests/salesAddons";
  import {checkUserPermissions} from "../../../../../assets/js/functions";
  import CustomDialog from "../../../../../components/customDialog/customDialog";
  import axios from "axios";

  export default {
    name: "saleAddonTrash",
    components: {CustomDialog},
    data () {
      return {
        requestSent: false,
        saleAddonDataForDelete: {},
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'saleAddonsTrash',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'storeAction',
            i18n: 'sales.addons.list.table.header.restore',
            color: 'success',
            width: '70px',
            minWidth: 70,
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'saleAddon:restore',
            action: {
              icon: 'icon-refresh-cw',
              iconPack: 'feather',
              color: 'success',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'deleteAction',
            i18n: 'sales.addons.list.table.header.delete',
            color: 'danger',
            width: '70px',
            minWidth: 70,
            align: 'center',
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'saleAddon:deleted',
            action: {
              icon: 'icon-trash',
              iconPack: 'feather',
              color: 'danger',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'name',
            i18n: 'sales.addons.list.table.header.name',
            align: 'center',
            width: '50%',
            minWidth: 200,
            sortable: true,
          },
          {
            field: 'rowNumber',
            i18n: 'sales.addons.list.table.header.row',
            align: 'center',
            width: '100px',
            minWidth: 100,
            footer: {}
          }
        ],
        actions: [
          {
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              {
                id: {name: 'salesAddons'},
                type: 'link',
                icon: 'icon-chevron-left',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created () {
      if (!checkUserPermissions('sale_addon.forceDelete')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'deleteAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      if (!checkUserPermissions('sale_addon.restore')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'storeAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
      }, 50)

      this.checkDeleteAndRestorePermission()
      this.getSaleAddonsTrash()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      checkDeleteAndRestorePermission () {
        if (!checkUserPermissions('sale_addon.forceDelete')) {
          for (let i = 0; i < this.columnsLabel.length; i++) {
            if (this.columnsLabel[i].field === 'deleteAction') {
              this.columnsLabel.splice(i, 1)
            }
          }
        }
        if (!checkUserPermissions('sale_addon.restore')) {
          for (let i = 0; i < this.columnsLabel.length; i++) {
            if (this.columnsLabel[i].field === 'storeAction') {
              this.columnsLabel.splice(i, 1)
            }
          }
        }
      },
      getSaleAddonsTrash () {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (this.data.length < this.total_count || !this.total_count) {
              if (this.$refs.saleAddonsTrash && this.data.length === 0) this.$refs.saleAddonsTrash.loadMoreStatus = 'FirstLoad'
              else if (this.$refs.saleAddonsTrash && this.data.length > 0) this.$refs.saleAddonsTrash.loadMoreStatus = 'Loading'

              getSaleAddonsTrash(this.page, this.filters, this.sorts).then((response) => {
                const saleAddons = response.data

                saleAddons.data.forEach((saleAddon) => {

                  this.data.push({
                    id: saleAddon.id,
                    rowNumber:  this.data.length + 1,
                    name: saleAddon.name || '',
                    price: saleAddon.price || ''
                  })
                })

                this.total_count = saleAddons.pagination.total
                this.page = saleAddons.pagination.current_page + 1

                if (response.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('rowNumber')
                  this.columnsLabel[row_index].footer.value = response.data.pagination.total
                }

                if (this.$refs.saleAddonsTrash) this.$refs.saleAddonsTrash.loadMoreStatus = ''
                this.requestSent = false
              }).catch((error) => {
                this.requestSent = false
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else if (this.$refs.saleAddonsTrash) this.$refs.saleAddonsTrash.loadMoreStatus = 'Danger'
              })
            } else {
              this.requestSent = false
            }
          }, 400)
        }
      },
      setFilter (filters) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'name':
              if (filters[key].search !== '') filters_list.push(`name=${filters[key].search}`)
              break

            case 'price':
              if (filters[key].search !== '') filters_list.push(`price=${filters[key].search},${  filters[key].type.id}`)
              break

            case 'financialStatus':
              if (filters[key].search.value > 0) filters_list.push(`financialStatus=${  filters[key].search.value}`)
              break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.getSaleAddonsTrash()
      },
      setSort (sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'name':
              sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'price':
              sorts_list.push(`order[0]=price,${  sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.getSaleAddonsTrash()
      },

      restoreSaleAddon (saleAddon) {
        restoreSaleAddon(saleAddon.id).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.addons.notifications.restore.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.page = 1
          this.total_count = null
          this.loadingTimer = 0
          this.data = []
          setTimeout(() => {
            this.getSaleAddonsTrash()
          }, 100)
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('sales.addons.notifications.restore.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
      },
      deleteSaleAddon (saleAddon) {
        deleteSaleAddon(saleAddon.id, true).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.addons.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.page = 1
          this.total_count = null
          this.loadingTimer = 0
          this.data = []
          setTimeout(() => {
            this.getSaleAddonsTrash()
          }, 100)
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 409:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.addons.notifications.delete.isUsedError'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.addons.notifications.delete.error'),
                    color: 'danger',
                    time: 2400,
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break
              }
            }
          })
      }
    }
  }
</script>

<style scoped>

</style>
